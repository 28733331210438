import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { NotificationsService } from 'src/app/services/notifications.service';
import { NotificationUserLocationSettings } from 'src/app/types/NotificationUserLocationSettings';
import { LocalizableComponent } from '../../../components/localizable/localizable.component';

@Component({
  selector: 'status-change-notifications',
  templateUrl: './status-change-notifications.component.html',
  styleUrls: ['./status-change-notifications.component.scss']
})
export class StatusChangeNotificationsComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() showStatusCheckboxes: boolean = false;
  @Input() settings: NotificationUserLocationSettings;
  @Output() statusChanged = new EventEmitter<{newSettings: NotificationUserLocationSettings, oldSettings: string}>();

  lastSettings: string;
  dataOutOfDate: boolean = false;

  disconnect$ = new Subject<boolean>();

  constructor(private notificationsService: NotificationsService) {
    super();
  }

  ngOnInit(): void {
    this.lastSettings = JSON.stringify(this.settings);

    this.notificationsService.notificationSettingsOutOfDate$.pipe(takeUntil(this.disconnect$)).subscribe(outOfDate => {
      this.dataOutOfDate = outOfDate;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.settings) {
      this.lastSettings = JSON.stringify(changes.settings.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  onCheckChanged() {
    let keys = Object.keys(this.settings.statusChangeNotifications);
    let anyChecked = keys.reduce((checked, key) => {
      return checked || this.settings.statusChangeNotifications[key];
    }, false);

    if (!anyChecked && this.settings.statusChange) {
      this.settings.statusChange = false;
    }

    this.statusChanged.emit({ newSettings: this.settings, oldSettings: this.lastSettings });
    this.lastSettings = JSON.stringify(this.settings);
  }

  toggleEnableNotification(enabled: boolean) {
    this.settings.statusChange = enabled;

    if (enabled) {
      let keys = Object.keys(this.settings.statusChangeNotifications);
      let anyChecked = keys.reduce((checked, key) => {
        return checked || this.settings.statusChangeNotifications[key];
      }, false);
      if (!anyChecked) {
        keys.forEach(key => {
          if (key !== 'weeklyPlannedRequest')
            this.settings.statusChangeNotifications[key] = true;
        });
      }
    }

    this.statusChanged.emit({ newSettings: this.settings, oldSettings: this.lastSettings });
  }
}
