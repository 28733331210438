import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastService } from 'src/app/services/toast.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { ApiService } from '../../../api.service';
import { AuthService } from '../../../auth/auth.service';
import { LocalizableComponent } from '../../../components/localizable/localizable.component';
import { DateService } from '../../../services/date.service';
import { UserRoleService } from "../../../services/user-role.service";
import { LocationProfile } from '../../../types/locationProfile';
import { LocationUser } from '../../../types/locationUser';
import { Role } from "../../../types/role";
import { UserLocationRole } from '../../../types/userLocationRole';
import { UserWithLocations } from '../../../types/userWithLocations';

@Component({
  selector: 'user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.scss']
})
export class UserAccessComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() location: LocationProfile;
  @Input() user: UserWithLocations;
  @Input() cellErrors: { userId: string, slic: string }[] = [];
  @Output() error: EventEmitter<{ hasError: boolean, userId: string, slic: string }> = new EventEmitter<{ hasError: boolean, userId: string, slic: string }>();
  @Output() userRoleChanged = new EventEmitter<{ user: UserWithLocations, location: UserLocationRole }>();

  role: Role = null;
  newRole: Role = null;
  currentRole: Role = null;
  requestedRoleText: string | null = null;
  roleClass: string = "";
  fullSlic: string = "";
  userLocation: UserLocationRole = null;
  userDataIsStale: boolean;
  revertSelection: Subject<number> = new Subject<number>();
  disconnect$: Subject<boolean> = new Subject<boolean>();
  hasError: boolean = false;
  get userAsLocationUser(): LocationUser {
    return {
      userId: this.user.userId,
      upsId: this.user.upsId,
      email: this.user.email,
      role: this.role,
      locationPermissionsEnabled: true,
      lastUpdatedAt: this.userLocation?.lastUpdatedAt ?? '',
      lastUpdatedBy: this.userLocation?.lastUpdatedBy ?? ''
    } as LocationUser;
  };
  lastModified: { lastUpdatedAt: string, lastUpdatedBy: string } = null;
  offset: number;

  private saveErrorToastId = 'role-change-error';

  roleList = [
    { name: this.localize(this.langSection.Role, this.langText.NoAccess), value: Role.NonUser, class: "no-access" },
    { name: this.localize(this.langSection.Role, this.langText.ViewOnly), value: Role.ReadonlyUser, class: "view-only" },
    { name: this.localize(this.langSection.Role, this.langText.ViewEdit), value: Role.User, class: "view-edit" },
    { name: this.localize(this.langSection.Role, this.langText.Administrator), value: Role.Admin, class: "admin" }
  ];

  constructor(private apiService: ApiService, private authService: AuthService, private modalService: NgbModal, private userRoleService: UserRoleService,
    private dateService: DateService, private userDataService: UserDataService, private toastService: ToastService) {
    super();
  }

  ngOnInit(): void {
    if (this.location && this.user) {
      this.userLocation = this.user.locations.find(l => l.slicNumber === this.location.slicNumber && l.countryCode === this.location.countryCode)
      this.role = this.userLocation?.role ?? Role.NonUser;
      this.roleClass = this.findStyling(this.role);
      this.fullSlic = `${this.location.countryCode}::${this.location.slicNumber}::${this.location.slicName}`;
      this.offset = this.dateService.getOffsetAndTimezoneForLocation(this.userLocation?.lastUpdatedAt, this.location).offset;
      this.lastModified = this.getLastModified(this.userLocation);
      if (this.cellErrors?.length)
        this.hasError = !!this.cellErrors?.find(cell => cell.slic === this.fullSlic && cell.userId === this.user.upsId);

      this.userDataService.isStale.pipe(takeUntil(this.disconnect$)).subscribe(x => {
        this.userDataIsStale = x;
        this.modifyRoleIfNotStale();
      });
    }
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  onRoleChange(val: String) {
    this.newRole = Number(val);
    this.currentRole = this.role;
    if (this.newRole !== this.currentRole) {
      this.userDataService.checkIfStale(this.location.slicName, this.user.userId, this.currentRole)
        .subscribe({
          next: locationUser => {
            this.userDataIsStale = locationUser !== null && locationUser.role?.toString() !== this.currentRole?.toString();
            if (this.userDataIsStale) {
              this.clearError();
              this.currentRole = Number(locationUser.role);
              this.newRole = this.currentRole;
              this.role = this.currentRole;
              this.revertSelection.next(this.currentRole);
              this.roleClass = this.findStyling(this.currentRole);
              this.user.locked = 0;
            }
            this.modifyRoleIfNotStale();
          },
          error: error => {
            this.newRole = this.currentRole;
            this.revertSelection.next(this.currentRole);
            this.roleClass = this.findStyling(this.currentRole);
            this.showError();
          }
        });
    }
  }

  findStyling(role: Role): string {
    return this.roleList.find((r) => r.value === role)?.class ?? "";
  }

  changeLocationRole() {
    if (this.userLocation) {
      let originalRole = this.userLocation.role;
      this.userLocation.role = this.role;
      this.user.locked = 2; // prevent updating until save process is complete - unlocked when refreshing users
      this.hasError = false;
      this.apiService.saveUser(this.fullSlic, this.userAsLocationUser).subscribe({
        next: response => {
          this.syncUserRole();
          this.user.locked = 2;
          this.clearError();
        },
        error: error => {
          // API call failed - return to original setting
          this.role = originalRole;
          this.roleClass = this.findStyling(this.role);
          this.showError();
        }
      });
    }
  }

  addUserToLocation() {
    if (this.userLocation)
      this.userLocation.role = this.role;
    this.user.locked = 2; // prevent updating until save process is complete - unlocked when refreshing users
    this.hasError = false;
    this.apiService.addUsers(this.fullSlic, [this.userAsLocationUser]).subscribe({
      next: response => {
        this.location.users++;
        this.syncUserRole();
        this.user.locked = 2;
        this.clearError();
      },
      error: error => {
        this.role = Role.NonUser;
        this.roleClass = this.findStyling(this.role);
        this.showError();
      }
    });
  }

  clearError() {
    this.hasError = false;
    this.toastService.removeToast(this.saveErrorToastId);
    this.removeErrorFromParent();
  }

  showError() {
    this.hasError = true;
    this.toastService.pushToast({
      id: this.saveErrorToastId,
      text: this.localize(this.langSection.Toast, this.langText.FailedToSaveChanges),
      duration: 10
    });
    this.pushErrorToParent();
  }

  syncUserRole() {
    let updatedUserLocation = {
      slicNumber: this.location.slicNumber,
      countryCode: this.location.countryCode,
      role: this.role,
      lastUpdatedAt: this.dateService.getCurrentLocalizedTimeAsUTCString(this.location),
      lastUpdatedBy: this.authService.getUniqueName()
    } as UserLocationRole;

    this.offset = this.dateService.getOffsetAndTimezoneForLocation(updatedUserLocation.lastUpdatedAt, this.location).offset;

    if (!this.userLocation) {
      this.user.locations.push(updatedUserLocation);
      this.userLocation = this.user.locations[this.user.locations.length - 1];
    }
    else {
      this.userLocation.role = this.role;
      this.userLocation.lastUpdatedAt = this.dateService.getCurrentLocalizedTimeAsUTCString(this.location);
      this.userLocation.lastUpdatedBy = this.authService.getUniqueName();
    }
    this.lastModified = this.getLastModified(this.userLocation);
    this.userRoleChanged.emit({ user: this.user, location: updatedUserLocation });
  }

  pushErrorToParent() {
    this.error.emit({ hasError: this.hasError, userId: this.user.upsId, slic: this.fullSlic });
  }

  removeErrorFromParent() {
    this.error.emit({ hasError: this.hasError, userId: this.user.upsId, slic: this.fullSlic });
  }

  getLastModified(loc: UserLocationRole): { lastUpdatedAt: string, lastUpdatedBy: string } {
    return (loc && loc.lastUpdatedAt && loc.lastUpdatedBy) ?
      {
        lastUpdatedAt: loc.lastUpdatedAt,
        lastUpdatedBy: loc.lastUpdatedBy
      } : null;
  }

  modifyRoleIfNotStale() {
    if (!this.userDataIsStale && this.currentRole !== this.newRole) {
      this.role = this.newRole;
      this.roleClass = this.findStyling(this.newRole);
      if (this.currentRole === Role.NonUser && this.newRole !== Role.NonUser) {
        this.addUserToLocation();
      }
      else {
        this.changeLocationRole();
      }
    } else {
      this.revertSelection.next(this.role);
      this.roleClass = this.findStyling(this.role);
      if (this.userDataIsStale) {
        this.user.locked = 0;
      }
    }
    this.newRole = this.currentRole;
  }

}