import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/api.service';
import { AppState } from 'src/app/app.state';
import { AuthService } from 'src/app/auth/auth.service';
import { LocalizableComponent } from 'src/app/components/localizable/localizable.component';
import { DateService } from 'src/app/services/date.service';
import { updateLocationProfile } from 'src/app/store/actions/location-list.actions';
import { LocationProfile } from 'src/app/types/locationProfile';

@Component({
  selector: 'custom-name-modal',
  templateUrl: './custom-name-modal.component.html',
  styleUrls: ['./custom-name-modal.component.scss']
})
export class CustomNameModalComponent extends LocalizableComponent implements OnInit, OnDestroy {
  @Input() modal;
  @Input() locationProfile: LocationProfile;

  disconnect$ = new Subject<boolean>();
  isSaving$ = new BehaviorSubject<boolean>(false);
  isSaving: Observable<boolean> = this.isSaving$.asObservable();
  customName: string = "";
  saveFailed: boolean;
  offset: number;

  constructor(private store: Store<AppState>, private apiService: ApiService, private authService: AuthService, private dateService: DateService) {
    super();
  }

  ngOnInit() {
    if (this.locationProfile && this.locationProfile.customNameLastUpdatedAt) {
      this.offset = this.dateService.getOffsetAndTimezoneForLocation(this.locationProfile.customNameLastUpdatedAt, this.locationProfile).offset;
    }
  }

  ngOnDestroy(): void {
    this.disconnect$.next(true);
    this.disconnect$.unsubscribe();
  }

  setCustomName() {
    this.isSaving$.next(true);
    let modifiableProfile = { ...this.locationProfile }
    if (modifiableProfile.customName !== this.customName) {
      modifiableProfile.customNameLastUpdatedAt = this.dateService.getCurrentLocalizedTimeAsUTCString(this.locationProfile);
      modifiableProfile.customNameLastUpdatedBy = this.authService.getUniqueName();
    }
    modifiableProfile.customName = this.customName;
    this.apiService.updateCustomName(modifiableProfile)
      .pipe(takeUntil(this.disconnect$))
      .subscribe(() => {
        this.saveFailed = false;
        this.store.dispatch(updateLocationProfile(modifiableProfile));
        this.isSaving$.next(false);
        this.modal.close();
      },
        () => {
          this.isSaving$.next(false);
          this.saveFailed = true;
        })
  }

  dismissModal() {
    this.modal.close();
  }

}
