// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-change-notifications {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
}

on-off-toggle.left-offset {
  padding-left: 0.5rem;
}

.status-settings {
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content;
  column-gap: 15px;
}

.form-check {
  padding: 0px 0px 0px 5px;
}

.form-group {
  padding: 0px 0px 0px 5px;
}

input[type=checkbox] {
  display: none;
}

.form-check label {
  padding: 5px;
  margin-left: -10px;
}

.form-check .checkbox:before {
  display: inline-block;
  content: "";
  width: 24px;
  height: 24px;
  color: black;
  border: 1px solid #979797;
  border-radius: 4px;
  cursor: pointer;
  vertical-align: middle;
}

input[type=checkbox]:checked + .checkbox:before {
  color: #00857D;
  content: "\\e622" !important;
  font-size: 11px;
  font-family: upsicons, fantasy !important;
  background: #fff;
  text-align: center;
  line-height: 24px;
  font-weight: bolder;
}

.checkbox.disabled:before {
  cursor: default !important;
  background-color: lightgray !important;
}

.info {
  color: #356DAE;
}`, "",{"version":3,"sources":["webpack://./src/app/notification-settings/location-settings-grid/status-change-notifications/status-change-notifications.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,kFAAA;EACA,gBAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,qBAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,sBAAA;AACF;;AAEA;EACE,cAAA;EACA,2BAAA;EACA,eAAA;EACA,yCAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAGE;EACE,0BAAA;EACA,sCAAA;AAAJ;;AAIA;EACE,cAAA;AADF","sourcesContent":[".status-change-notifications {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 40px;\n}\n\non-off-toggle.left-offset {\n  padding-left: .5rem;\n}\n\n.status-settings {\n  display: grid;\n  grid-template-columns: max-content max-content max-content max-content max-content;\n  column-gap: 15px;\n}\n\n.form-check {\n  padding: 0px 0px 0px 5px;\n}\n\n.form-group {\n  padding: 0px 0px 0px 5px;\n}\n\ninput[type=\"checkbox\"] {\n  display: none;\n}\n\n.form-check label {\n  padding: 5px;\n  margin-left: -10px;\n}\n\n.form-check .checkbox:before {\n  display: inline-block;\n  content: '';\n  width: 24px;\n  height: 24px;\n  color: black;\n  border: 1px solid #979797;\n  border-radius: 4px;\n  cursor: pointer;\n  vertical-align: middle;\n}\n\ninput[type=\"checkbox\"]:checked+.checkbox:before {\n  color: #00857D;\n  content: '\\E622' !important;\n  font-size: 11px;\n  font-family: upsicons, fantasy !important;\n  background: #fff;\n  text-align: center;\n  line-height: 24px;\n  font-weight: bolder;\n}\n\n.checkbox.disabled {\n  &:before {\n    cursor: default !important;\n    background-color: lightgray !important;\n  }\n}\n\n.info {\n  color: #356DAE;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
