import { LocationProfile } from "./locationProfile";
import { StatusChangeNotifications } from "./StatusChangeNotifications";

export class NotificationUserLocationSettings {
    notificationSettingsId: string;
    upsIdLower: string;
    slicNumber: string;
    countryCode: string;
    statusChange: boolean = false;
    statusChangeNotifications: StatusChangeNotifications = {
        attentionNeeded: false,
        pending: false,
        approved: false,
        scheduled: false,
        inTransit: false,
        onProperty: false,
        completed: false,
        canceled: false,
        weeklyPlannedRequest: false
    };

    comment: boolean = false;
    requestEdited: boolean = false;
    userRequest: boolean = false;

    slicName?: string;
    customName?: string;
    address?: string;
    lastModifiedUtc?: string;

    constructor(location?: LocationProfile, upsIdLower?: string) {
        if (location) {
            this.slicNumber = location.slicNumber;
            this.countryCode = location.countryCode;
            this.slicName = location.slicName;
            this.customName = location.customName;
            this.address = location.address;
            this.notificationSettingsId = `${upsIdLower}::${this.countryCode}::${this.slicNumber}`;
        }
    }
}

export class NotificationSettingsErrors {
    statusChange: boolean = false;
    comment: boolean = false;
    requestEdited: boolean = false;
    userRequest: boolean = false;
}

